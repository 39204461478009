html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Acherus Grotesque";
}

@font-face {
  font-family: "Addington CF";
  src: url("./shared/assets/fonts/AddingtonCF-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Addington CF";
  src: url("./shared/assets/fonts/AddingtonCF-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Addington CF";
  src: url("./shared/assets/fonts/AcherusGrotesque-RegularItalic.otf")
    format("opentype");
  font-style: italic;
}

@font-face {
  font-family: "Acherus Grotesque";
  src: local("Acherus Grotesque"),
    url("./shared/assets/fonts/AcherusGrotesque-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Acherus Grotesque";
  src: url("./shared/assets/fonts/AcherusGrotesque-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Acherus Grotesque";
  src: url("./shared/assets/fonts/AcherusGrotesque-RegularItalic.otf")
    format("opentype");
  font-style: italic;
}