.compositionCardContainer {
  position: relative;
  /* padding-left: 8px;
  padding-right: 8px; */
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: bottom bottom !important;
  height: 100%;
}

.paperComposition {
  display: flex;
  height: 100%;
  border-radius: 8px !important;
}

.paperCompositionBox {
  display: flex;
  border: 1px solid #00859a;
  border-radius: 8px;
  padding: 8px;
  position: relative;
}

.paperImageComposition,
.paperImageActivator {
  position: "relative";
  margin-top: -64px;

  img {
    height: 294px;
    width: 140px;
    object-fit: contain;
  }
}

.divider {
  background: #0ba7bc;
  height: 16px;
}

@media screen and (max-width: 768px) {
  .paperCompositionBox {
    padding: 16px;
  }

  .paperImageComposition {
    margin-top: auto;
    margin-right: -35px;
    margin-bottom: -30px;
  }

  .paperImageComposition,
  .paperImageActivator {
    img {
      width: 90px;
    }
  }

  .paperImageActivatorResponsive {
    margin-left: -34px;
    margin-top: -72px;

    img {
      width: 90px;
    }
  }

  .paperCompositionActivator {
    margin-top: 48px !important;
  }
}
