.avatar {
  display: inline-flex;
  border-radius: 5% !important;
  width: 22rem !important;
  height: 22rem !important;
  overflow: visible !important;
}

.categoryContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow: hidden;

  border-radius: 5% !important;
  img {
    object-fit: cover;
    height: 23rem !important;
    width: 23rem !important;
  }
}

.categoryBoxItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}

.categoryItem {
  background-color: #ffffff;
  color: #000000;
  padding: 5px;
  border-radius: 50%;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
